import React from "react";
import "./gallery.scss";
import { graphql } from "gatsby";
import Lightbox from "../../components/Lightbox/Lightbox";
import useTitleContext from "../../context/useTitleContext";
import BackBtn from "../../components/BackBtn/BackBtn";
import Seo from "../../components/seo";

function Gallery({ data }) {
  const markdownRemark = data.markdownRemark;
  const html = markdownRemark?.html;
  const title =
    markdownRemark.frontmatter?.h1 || `Bildergallerie mit ${title} bildern`;
  useTitleContext(title);
  //check if there is an additional article
  console.log("html" + html);

  return (
    <>
      <Seo title={title} description={title}></Seo>
      <BackBtn></BackBtn>
      {html && (
        <article
          className="article container"
          dangerouslySetInnerHTML={{ __html: html }}
        ></article>
      )}
      <div className="gallery">
        {markdownRemark.frontmatter.smallImage.map((image, index) => {
          const caption = markdownRemark.frontmatter?.imageCaption
            ? markdownRemark.frontmatter.imageCaption[index]
            : "";
          return (
            <Lightbox
              key={index}
              small={image}
              large={markdownRemark.frontmatter.largeImage[index]}
              caption={caption}
              modifier="--gallery"
            ></Lightbox>
          );
        })}
      </div>
    </>
  );
}

export const query = graphql`
  query MyQuery($path: String) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      frontmatter {
        h1
        imageCaption
        smallImage: image {
          childImageSharp {
            gatsbyImageData(
              height: 200
              width: 300
              transformOptions: { fit: COVER, cropFocus: ENTROPY }
            )
          }
        }
        largeImage: image {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1.428
              transformOptions: { fit: CONTAIN }
              backgroundColor: "transparent"
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;

export default Gallery;
